<template>
  <div class="autocomplete2">
    <b-input type="text" v-model="search" @input="onChange" :placeholder="placeholder" autocomplete="off" @focus="onFocus" />
    <ul v-show="isOpen" class="autocomplete2-results">
      <li v-for="(result, i) in results" :key="i" @click="setResult(result)" class="autocomplete2-result">{{ result[value_field] }}</li>
    </ul>
  </div>
</template>
<script>
  export default {
    name: 'InputAutocomplete2',
    props: {
      initval: {
        type: Number,
        required: false,
        default: 0,
      },
      items: {
        type: Array,
        required: false,
        default: () => [],
      },
      placeholder: {
        type: String,
        required: false,
        default: '',
      },
      isAsync: {
        type: Boolean,
        required: false,
        default: false,
      },
      key_field: {
        type: String,
        required: false,
        default: 'IDn',
      },
      value_field: {
        type: String,
        required: true,
        default: '',
      },
    },
    data() {
      return {
        search: '',
        results: [],
        isOpen: false,
      };
    },
    watch: {
      "$data.isOpen": {
        handler: function (newValue) {
          this.wrapperFix(newValue);
        },
        deep: true,
      },
    },
    methods: {
      wrapperFix(isOpenCheck) {
        let wrappers = document.getElementsByClassName("el-collapse-item__wrap");
        for (let i = 0; i < wrappers.length; i++) {
          if (isOpenCheck) {
            if (!wrappers[i].classList.contains("fixWrap")) {
              wrappers[i].classList.add("fixWrap");
            }
          } else {
            if (wrappers[i].classList.contains("fixWrap")) {
              wrappers[i].classList.remove("fixWrap");
            }
          }
        }
      },
      onFocus() {
        this.isOpen = true;
        this.filterResults();
      },
      onChange() {
        this.isOpen = true;
        this.filterResults();
        if (this.results.length == 1 && this.search == this.results[0][this.value_field]) {
          this.$emit('input', this.results[0]);
        }
        else {
          var rslt = {};
          rslt[this.key_field] = 0;
          rslt[this.value_field] = this.search;
          this.$emit('input', rslt);
        }
      },
      filterResults() {
        this.results = this.items.filter(item => item[this.value_field].toLowerCase().indexOf(this.search.toLowerCase()) > -1);
      },
      setResult(result) {
        this.search = result[this.value_field];
        this.filterResults();
        this.$emit('input', result);
        this.isOpen = false;
      },
      handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          if (this.results.length == 1 && this.search.toLowerCase() == this.results[0][this.value_field].toLowerCase()) {
            this.search = this.results[0][this.value_field];
            this.$emit('input', this.results[0]);
          }
          this.isOpen = false;
        }
      },
    },
    mounted() {
      // console.log(this.value_field+' this.initval: ', this.initval);
      for (let key in this.items) {
        if (this.items[key][this.key_field] == this.initval) this.search = this.items[key][this.value_field]
      }
      this.filterResults();
      document.addEventListener('click', this.handleClickOutside);
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside);
    }
  };
</script>
<style>
  .autocomplete2 {
    position: relative;
  }
  .autocomplete2-results {
    padding: 0;
    margin: 5px 0;
    border: 1px solid #cad1d7;
    border-radius: 4px;
    height: 150px;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
    overflow: auto;
    position: absolute;
    background-color: white;
    color: #052b24;
    width: 100%;
    z-index: 1;
    font-size: 1rem;
  }

  .autocomplete2-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
    z-index: 1;
  }

  .autocomplete2-result:hover {
    background-color: #4AAE9B;
    color: white;
  }

  .fixWrap {
    display: contents;
    content: "";
    clear: both;
    width: calc(100% - 5px);
    overflow-y: visible;
  }
</style>